const Colors = {
    orange_dk: "#ED760A",
    orange: "#F9A01B",
    orange_lt: "#fef6eb",
    charcoal: "#1A1A1A",
    blue: "#50B4F3",
    grey: "#555",
    grey_lt: "#999",
    container: "#F1F1F1",
    container_lt: "#F8F8F8",
    tag1: "#1BFACD",
    tag2: "#A8E617",
    tag3: "#F9A01B",
    tag4: "#E62263",
    tag5: "#2014FF",
    tag6: "#0FFBB7",
    tag7: "#0FFBB7"
}

export default Colors;